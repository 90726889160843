ul li a {
  color: #37475c;
  font-weight: 400;
}

ul li a:hover {
  color: #00a8ff;
}

ul li a.active {
  color: #37475c;
  font-weight: 700;
}
button {
  background: #000;
  box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);
  color: #fff;
  padding: 12px 25px;
  border: none;
  cursor: pointer;
}

ul {
  list-style: none;
  padding: unset;
  margin: unset;
}

li {
  padding: 14px;
  transition: all linear 0.3s;
}

/* li:hover {
  background: gray;
  cursor: pointer;
  color: #fff;
} */

.dropdown-menu {
  background: #d9d9d9;
  box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);
  transition: all linear 0.3s;
}
